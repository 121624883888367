<template>
  <b-form-group>
    <label v-if="label">{{ label }}</label>

    <v-select
      ref="selectRef"
      v-model="selected"
      :filterable="false"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      label="title"
      :class="tablename === 'branch' ? 'select-class' : ''"
      :options="paginated"
      :loading="loading"
      :placeholder="placeholder"
      @search="query => search = query"
      @click.native="onClick"
    >

      <li
        v-if="getDataByTableName().length >= limit "
        slot="list-footer"
        class="pagination"
      >
        <b-button
          v-show="hasPrevPage"
          variant="relief-secondary"
          :disabled="!hasPrevPage"
          @click="offset -= limit"
        >
          Prev
        </b-button>

        <b-button
          v-show="hasNextPage"
          variant="relief-primary"
          :disabled="!hasNextPage"
          @click="offset += limit"
        >
          Next
        </b-button>
      </li>
    </v-select>

  </b-form-group>
</template>

<script>
import vSelect from 'vue-select'
import { heightFade } from '@core/directives/animations'
import {
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { isDef } from '@/assets/helpers'
import { branchModelField } from '@/api/field_model'
import AuthMixin from '@/mixins/authMixin'

export default {
  components: {
    BFormGroup,
    BButton,
    vSelect,
  },
  directives: {
    'height-fade': heightFade,
  },
  mixins: [AuthMixin],
  props: {
    tablename: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    placeholderO: {
      type: String,
      default: null,
    },
    branchType: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      options: [],
      search: '',
      offset: 0,
      limit: 6,
      loading: false,
      selected: null,
    }
  },
  computed: {
    placeholder() {
      return this.placeholderO ? this.placeholderO : `Select ${this.tablename}`
    },
    filtered() {
      return this.options.filter(item => item.name.includes(this.search))
    },
    paginated() {
      return this.filtered.slice(this.offset, this.limit + this.offset)
    },
    hasNextPage() {
      const nextOffset = this.offset + this.limit
      return Boolean(this.options.slice(nextOffset, this.limit + nextOffset).length)
    },
    hasPrevPage() {
      const prevOffset = this.offset - this.limit
      return Boolean(this.options.slice(prevOffset, this.limit + prevOffset).length)
    },
  },
  watch: {
    selected(v) {
      if (v) {
        this.$emit('selectedData', (this.tablename === 'branch' || this.tablename === 'stockIn') ? v : v.id)
      }
    },
  },

  methods: {
    onClick() {
      console.log('this.options', this.options)
      if (this.options.length <= 0) {
        this.loading = true
        this.fetchOptions()
      }
    },
    getDataByTableName() {
      let dataPerTable = []
      console.log(this.tablename, 'this.tablename')
      const state = this.$store.state.collections
      switch (this.tablename) {
        case 'branch':
          console.log('branch', this.branchType)
          if (isDef(this.branchType)) {
            dataPerTable = state.branches.filter(v => v[branchModelField.type] === this.branchType)
          } else if (this.multipleSalesBranchId.length >= 1) {
            const b = []
            // eslint-disable-next-line no-plusplus
            for (let index = 0; index < state.branches.length; index++) {
              const element = state.branches[index]
              // eslint-disable-next-line radix
              if (this.multipleSalesBranchId.map(v => parseInt(v)).includes(element.id)) {
                b.push(element)
              }
            }
            dataPerTable = b
          } else {
            dataPerTable = state.branches
          }
          break
        case 'groups':
          dataPerTable = state.groups
          break
        case 'marks':
          dataPerTable = state.marks
          break
        case 'brands':
          dataPerTable = state.brands
          break
        case 'stockIn':
          dataPerTable = state.suppliers
          break
        case 'measurements':
          dataPerTable = state.measurements
          break
        default:
          break
      }
      return dataPerTable
    },
    showToast(title, text) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'icon-alert-circle',
          text,
          variant: 'danger',
        },
      },
      {
        timeout: 2500,
        position: 'bottom-right',
      })
    },
    async fetchOptions() {
      console.log('this.tablename', this.tablename)
      if (this.getDataByTableName().length > 0) {
        this.loading = false
        this.options = this.getDataByTableName().map(element => ({ title: element.name, ...element }))
      } else {
        try {
          await this.$store.dispatch('collections/getCollections', this.tablename)
          this.loading = false
          this.options = this.getDataByTableName().map(element => ({ title: element.name, ...element }))
        } catch (error) {
          this.loading = false
          this.showToast('Error', error.message)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
  .select-class {
    width: 280px;
  }
 .pagination {
    display: flex;
    margin: .40rem .25rem .25rem;
  }
  .pagination button {
    flex-grow: 1;

  }
  .pagination button:first-child {
    margin-right: 5px;
  }
  .pagination button:hover {
    cursor: pointer;
  }
#modal-prevent-closing {
  .form-control {
    text-align: center;
  }
}
</style>
